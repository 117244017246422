import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";

const DelModalConfirmation = ({
  showModal,
  setShowModal,
  title,
  subTitle,
  onDelete,
  cancelBtnText,
  submitBtnText,
  confirmationText,
  handleClose,
  isLoading,
}: any) => {
  const handleCloseInner = () => {
    setShowModal("");
  };
  const handleClose2 = handleClose ? handleClose : handleCloseInner;

  const [isMatched, setIsMatched] = useState(true);

  const onChangeHandler = (e: any) => {
    if (confirmationText === e.target.value) {
      setIsMatched(false);
    } else {
      setIsMatched(true);
    }
  };

  return (
    <>
      <div>
        <Modal show={showModal} onHide={handleClose2} size={"lg"}>
          <Modal.Header onHide={handleClose2} closeButton>
            <Modal.Title as="h3">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{subTitle || `Are you sure to delete`}</h4>
            <p style={{ display: "inline" }}>
              Enter <b>"{confirmationText}"</b> to delete it permanently
            </p>
            <div style={{ marginTop: "10px" }}>
              <input onChange={(e) => onChangeHandler(e)} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={handleClose2}
              style={{ border: "2px solid #041c54" }}
            >
              {cancelBtnText || "Discard"}
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => onDelete()}
              disabled={isMatched || isLoading}
            >
              {submitBtnText || "Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default DelModalConfirmation;
