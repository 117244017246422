import axios from "axios";
const base = process.env.REACT_APP_BACKEND_DOMAIN;

export const getAllCrosscheckUsers = async (perPage: number, page: number) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/crosscheck-users?page=${page}&perPage=${perPage}`,
    {},
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};
