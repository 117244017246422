
// account
function login(params: { email: string; password: string }) {
  const baseUrl = "/login/";
  return "";
}

function logout() {
  const baseUrl = "/logout/";
  return "";
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return "api.create(`${baseUrl}`, params)";
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return "api.create(`${baseUrl}`, params);"
}

export { login, logout, signup, forgotPassword };
