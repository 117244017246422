import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Toast,
  Form,
  FormLabel,
} from "react-bootstrap";

import { compareAsc, format } from "date-fns";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import Table from "../../../../components/Table";
import { FormInput, VerticalForm } from "../../../../components";
import {
  addUser,
  deleteUser,
  getAllUsers,
  updateUser,
} from "../../api/user-managment";
import FileUploader from "../../../../components/FileUploader";
import SelectInput from "../../../../components/SelectInput";
import useToaster from "../../toast";
import Switch from "../../../../components/Switch";
import DeleteModal from "../../delete-modal";
import FeatherIcon from "feather-icons-react";
import FeatherIcons from "../../../icons/Feather";
import { getAllCrosscheckUsers } from "../../api/users";
import {
  getSingleWorkspaceInvitees,
  getSingleWorkspaceUsers,
} from "../../api/workspaces";

const Users = () => {
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [isInvitee, setIsInvitee] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [usersCount, setUsersCount] = useState<number>(0);
  const [invitees, setInvitees] = useState([]);
  const { onSuccess, onError, toastComponent } = useToaster();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationDataLoading, setPaginationDataLoading] = useState(false);

  const workspaceId = searchParams.get("workspace");

  const perPage = 12;
  const totalPages = Math.ceil(usersCount / perPage);

  const onPageChange = async (updatedPage: any) => {
    try {
      const body = {
        lastAccessedWorkspace: workspaceId,
      };
      setCurrentPage(updatedPage);
      setPaginationDataLoading(true);
      const res = await getSingleWorkspaceUsers(body, perPage, updatedPage);
      setUsers(res?.data?.users || []);
      setUsersCount(res?.data?.count || 0);
      setPaginationDataLoading(false);
    } catch (error) {
      onError((error as any)?.response?.data?.msg || "Error occurred");
      setPaginationDataLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsUsersLoading(true);
        const body = {
          lastAccessedWorkspace: workspaceId,
        };
        const res = await getSingleWorkspaceUsers(body, perPage, 1);
        const res2 = await getSingleWorkspaceInvitees(body);
        setUsers(res?.data?.users || []);
        setUsersCount(res?.data?.count || 0);
        setInvitees(res2?.data?.invitees?.invitees);
        setIsUsersLoading(false);
      } catch (error) {
        onError((error as any)?.response?.data?.msg || "Error occurred");
        setIsUsersLoading(false);
        console.log(error);
      }
    })();
  }, []);

  const transformTableData = (users: any) => {
    const transformedData = users.map((user: any, index: number) => {
      return {
        id: index + 1,
        _id: user?._id,
        name: user?.name,
        email: user?.email,
        role: user?.role,
        status:
          user?.status === true
            ? "Active"
            : user?.status === true
            ? "Inactive"
            : "",
        lastActive: format(new Date(2014, 1, 11), "yyyy-MM-dd hh:mm a"),
        profilePicture: user?.profilePicture ? (
          <img src={`${user?.profilePicture}`} height={"34px"} />
        ) : (
          ""
        ),
      };
    });
    return transformedData;
  };

  const transformedUsersData = transformTableData(isInvitee ? invitees : users);

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: users?.length,
    },
  ];
  const activeUserColumns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Picture",
      accessor: "profilePicture",
      sort: true,
    },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Email",
      accessor: "email",
      sort: true,
    },
    {
      Header: "Role",
      accessor: "role",
      sort: true,
    },
    {
      Header: "Last Active",
      accessor: "lastActive",
      sort: true,
    },
  ];

  const invitedUserColumns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Email",
      accessor: "email",
      sort: true,
    },
    {
      Header: "Role",
      accessor: "role",
      sort: true,
    },
  ];

  const userSwitchHandler = () => {
    setIsInvitee(!isInvitee);
  };

  return (
    <>
      {toastComponent}
      <div>
        {isUsersLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "calc(60vh - 50px)",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <div
              style={{
                height: "70vh",
              }}
            >
              <div
                style={{
                  margin: "0px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h4>
                  {isInvitee
                    ? `Invited Users (${invitees?.length})`
                    : `Active Users (${usersCount})`}
                </h4>
                <Button variant="primary" onClick={userSwitchHandler}>
                  {isInvitee ? "Active Users" : "Invited Users"}
                </Button>
              </div>
              {users?.length > 0 ? (
                <Table
                  columns={isInvitee ? invitedUserColumns : activeUserColumns}
                  data={transformedUsersData}
                  sizePerPageList={sizePerPageList}
                  isSelectable={true}
                  isSortable={true}
                  paginationWithApi={
                    !isInvitee &&
                    totalPages > 1 && {
                      onPageChange,
                      totalPages,
                      currentPage,
                      isLoading: paginationDataLoading,
                    }
                  }
                />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "calc(60vh - 50px)",
                    opacity: "0.5",
                  }}
                >
                  <h4>No Users</h4>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Users;
