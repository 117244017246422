import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";

const DeleteModal = ({
  showModal,
  setShowModal,
  title,
  subTitle,
  onDelete,
  cancelBtnText,
  submitBtnText,
  handleClose,
}: any) => {
  const handleCloseInner = () => {
    setShowModal("");
  };
  const handleClose2 = handleClose ? handleClose : handleCloseInner;

  return (
    <>
      <Modal show={showModal} onHide={handleClose2} size={"sm"}>
        <Modal.Header onHide={handleClose2} closeButton>
          <Modal.Title as="h3">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {subTitle || `Are you sure to delete user `}
            <h4 style={{ display: "inline" }}>{showModal?.row?.name}</h4>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={handleClose2}
            style={{ border: "2px solid #041c54" }}
          >
            {cancelBtnText || "Discard"}
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="edit"
            onClick={() => onDelete()}
          >
            {submitBtnText || "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteModal;
