export const downloadCSV = (data: any, filename = "resultCSV") => {
  if (!data || !data.length) {
    return;
  }

  const keys = Object.keys(data[0]);
  const csvContent = [
    keys.join(","), // Header row
    ...data.map((row: any) => keys.map((key) => row[key]).join(",")), // Data rows
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  if (link.download !== undefined) {
    // Feature detection
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${filename}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
