import axios from "axios";
const base = process.env.REACT_APP_BACKEND_DOMAIN;

export const getAllUsers = async () => {
  const token = localStorage.getItem("token");
  const res = axios.get(`${base}/api/admin-portal/users`, {
    headers: { Authorization: `${token}` },
  });
  return res;
};
export const addUser = async (body: any) => {
  const token = localStorage.getItem("token");

  const res = axios.post(`${base}/api/admin-portal/add-user`, body, {
    headers: { Authorization: `${token}` },
  });
  return res;
};
export const updateUser = async (id: any, body: any) => {
  const token = localStorage.getItem("token");
  const res = axios.put(`${base}/api/admin-portal/update-user/${id}`, body, {
    headers: { Authorization: `${token}` },
  });
  return res;
};
export const deleteUser = async (id: any) => {
  const token = localStorage.getItem("token");
  const res = axios.delete(`${base}/api/admin-portal/delete-user/${id}`, {
    headers: { Authorization: `${token}` },
  });
  return res;
};
