import React, { useState, useEffect } from "react";
import { Toast } from "react-bootstrap";
import logo from "../../../assets/images/cross-check-logo.png";
import "./toast.scss";

const useToaster = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(true);

  const onSuccess = (msg: any) => {
    setMessage(msg);
    setIsSuccess(true);
    setShow(true);
  };

  const onError = (msg: any) => {
    setMessage(msg);
    setIsSuccess(false);
    setShow(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 2500);
    return () => clearTimeout(timeout);
  }, [show]);

  const toastComponent = (
    <Toast
      className={`main toast-enter ${show ? "" : "toast-exit"} ${
        isSuccess ? "success" : "error"
      }`}
      onClose={() => setShow(false)}
      show={show}
    >
      <Toast.Header className="d-flex justify-content-between">
        <img src={logo} alt="brand-logo" height="18" className="me-1" />
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );

  return { onSuccess, onError, toastComponent };
};

export default useToaster;
