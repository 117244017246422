import React, { useEffect, useMemo, useState } from "react";

import { compareAsc, format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/Table";
import { getAllCrosscheckUsers } from "../api/users";
import styles from "./users.module.scss";
import useToaster from "../toast";
import Workspace from "../workspaces/workspace/workspace";
import { records } from "../../tables/data";
import { set } from "lodash";
import { Button } from "react-bootstrap";
import { downloadCSV } from "../utils/fileHandler";

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [usersCount, setUsersCount] = useState<number>(0);
  const { onError, onSuccess, toastComponent } = useToaster();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationDataLoading, setPaginationDataLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const workspaceId = searchParams.get("workspace");
  const totalPages = Math.ceil(usersCount / perPage);

  useEffect(() => {
    setCurrentPage(1);
    onPageChange(1);
  }, [perPage]);

  const onPageChange = async (updatedPage: any) => {
    try {
      setCurrentPage(updatedPage);
      setPaginationDataLoading(true);

      const res =
        usersCount === perPage
          ? await getAllCrosscheckUsers(0, 0)
          : await getAllCrosscheckUsers(perPage, updatedPage);
      setUsers(res?.data?.users || []);
      setUsersCount(res?.data?.count || 0);
      setPaginationDataLoading(false);
    } catch (error) {
      setPaginationDataLoading(false);
      onError((error as any)?.response?.data?.msg);
      console.log(error);
    }
  };

  const getAllCrosscheckUsersHandler = async (perPage: number, page = 1) => {
    try {
      setIsUsersLoading(true);
      const res = await getAllCrosscheckUsers(perPage, page);
      setUsers(res?.data?.users || []);
      setUsersCount(res?.data?.count || 0);
      setIsUsersLoading(false);
    } catch (error) {
      onError((error as any)?.response?.data?.msg || "Error occurred");
      setIsUsersLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCrosscheckUsersHandler(perPage, 1);
  }, []);

  const transformTableData = (users: any) => {
    const transformedData = users?.map((user: any, index: number) => {
      return {
        id: perPage * (currentPage - 1) + index + 1,
        _id: user?._id,
        name: user?.name,
        email: user?.email,
        timeZone: user?.timeZone,
        signUpType: user?.signUpType,
        darkMode: user?.darkMode ? "Enabled" : "Disabled",
        workspaces: user?.workspaces.map((workspace: any) => (
          <div
            onClick={() =>
              setSearchParams({
                workspace: workspace?.workSpaceId?._id,
                name: workspace?.workSpaceId?.name,
              })
            }
            style={{ cursor: "pointer" }}
          >
            {workspace?.workSpaceId?.name}
          </div>
        )),
        defaultStorage: user?.checksDefaultStorage,
        storageUsed:
          user?.occupiedS3Space?.totalSizeInMb < 1000
            ? `${Math.floor(user?.occupiedS3Space?.totalSizeInMb)} Mbs`
            : `${user?.occupiedS3Space?.totalSizeInGb} Gbs`,
        status:
          user?.status === true
            ? "Active"
            : user?.status === true
            ? "Inactive"
            : "",
        lastActive: format(new Date(2014, 1, 11), "yyyy-MM-dd hh:mm a"),
        profilePicture: user?.profilePicture ? (
          <img src={`${user?.profilePicture}`} height={"34px"} />
        ) : (
          ""
        ),
      };
    });
    return transformedData;
  };

  const transformTableDataCSV = (users: any) => {
    const transformedData = users?.map((user: any, index: number) => {
      return {
        id: perPage * (currentPage - 1) + index + 1,
        name: user?.name,
        email: user?.email,
        timeZone: user?.timeZone,
        signUpType: user?.signUpType,
        darkMode: user?.darkMode ? "Enabled" : "Disabled",
        workspaces: user?.workspaces
          .map((workspace: any) => workspace?.workSpaceId?.name)
          .join(" "),
        defaultStorage: user?.checksDefaultStorage,
        storageUsed:
          user?.occupiedS3Space?.totalSizeInMb < 1000
            ? `${Math.floor(user?.occupiedS3Space?.totalSizeInMb)} Mbs`
            : `${user?.occupiedS3Space?.totalSizeInGb} Gbs`,
        status:
          user?.status === true
            ? "Active"
            : user?.status === true
            ? "Inactive"
            : "",
        lastActive: format(new Date(2014, 1, 11), "yyyy-MM-dd hh:mm a"),
        profilePicture: user?.profilePicture ? "yes" : "",
      };
    });
    return transformedData;
  };

  const transformedUsersData = useMemo(
    () => transformTableData(users),
    [users]
  );

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: usersCount,
    },
  ];
  const userColumns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },

    {
      Header: "Profile Picture",
      accessor: "profilePicture",
    },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Email",
      accessor: "email",
      sort: true,
    },
    {
      Header: "Dark Mode",
      accessor: "darkMode",
      sort: true,
    },
    {
      Header: "Workspaces",
      accessor: "workspaces",
      sort: true,
    },
    {
      Header: "Default Storage",
      accessor: "defaultStorage",
      sort: true,
    },
    {
      Header: "Storage used",
      accessor: "storageUsed",
      sort: true,
    },
    {
      Header: "Signup via",
      accessor: "signUpType",
      sort: true,
    },
    {
      Header: "Time Zone",
      accessor: "timeZone",
      sort: true,
    },
  ];

  const csvExportHandler = async (users: any) => {
    const dataToBeExported = transformTableDataCSV(users);
    downloadCSV(dataToBeExported, "crosscheck users");
  };

  return (
    <>
      {!workspaceId && (
        <div className={`${styles.main}`}>
          {toastComponent}
          <div className="d-flex justify-content-between">
            <h3>{`Users (${usersCount})`}</h3>
            <Button onClick={() => csvExportHandler(users)}>Export CSV</Button>
          </div>
          <div style={{ overflowY: "scroll", height: "87vh" }}>
            {isUsersLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "calc(80vh - 50px)",
                }}
              >
                <Spinner />
              </div>
            ) : users?.length > 0 ? (
              <Table
                columns={userColumns}
                data={transformedUsersData}
                sizePerPageList={sizePerPageList}
                isSelectable={true}
                isSortable={true}
                isSearchable={true}
                pagination={true}
                paginationWithApi={{
                  onPageChange,
                  totalPages,
                  currentPage,
                  isLoading: paginationDataLoading,
                }}
                setPerPage={setPerPage}
                perPage={perPage}
                pageSize={perPage}
              />
            ) : (
              <div className="d-flex justify-content-center ">
                <div className="d-flex flex-column align-items-center">
                  <h1 className="mb-4" style={{ opacity: "0.5" }}>
                    No user
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {workspaceId && <Workspace />}
    </>
  );
};

export default Users;
