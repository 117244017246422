import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Toast,
  Form,
  FormLabel,
} from "react-bootstrap";

import { compareAsc, format } from "date-fns";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/Table";
import { FormInput, VerticalForm } from "../../../components";
import {
  addUser,
  deleteUser,
  getAllUsers,
  updateUser,
} from "../api/user-managment";
import FileUploader from "../../../components/FileUploader";
import "./user-management.scss";
import { convertToBase64 } from "./helper";
import SelectInput from "../../../components/SelectInput";
import useToaster from "../toast";
import Switch from "../../../components/Switch";
import DeleteModal from "../delete-modal";
import FeatherIcon from "feather-icons-react";
import FeatherIcons from "../../icons/Feather";
import { downloadCSV } from "../utils/fileHandler";

const UserAccount = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [editUser, setEditUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deleteUserRecord, setDeleteUserRecord] = useState<any>();
  const createUser = Boolean(searchParams.get("createUser"));
  const { onSuccess, onError, toastComponent } = useToaster();
  const permittedRoles = ["Super Admin", "Admin"];
  let userRole;

  const user = localStorage.getItem("user");
  if (user) {
    userRole = JSON.parse(user)?.role;
  }

  interface UserData {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    roleName: string;
    profilePicture: any;
    status: boolean;
  }
  const schemaResolver2 = editUser
    ? yupResolver(
        yup.object().shape({
          name: yup.string().required("Please enter name"),
          email: yup.string().required("Please enter email"),
          roleName: yup.string().required("Please select a role"),
        })
      )
    : yupResolver(
        yup.object().shape({
          name: yup.string().required("Please enter name"),
          email: yup.string().required("Please enter email"),
          roleName: yup.string().required("Please select a role"),
          password: yup
            .string()
            .required("Please enter password")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
              "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, and one digit"
            ),
          confirmPassword: yup
            .string()
            .label("confirm password")
            .required()
            .oneOf([yup.ref("password"), null], "Passwords must match"),
        })
      );

  const methods = useForm<UserData>({
    resolver: schemaResolver2,
  });

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = methods;

  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    (async () => {
      setIsUsersLoading(true);
      const res = await getAllUsers();
      setUsers(res?.data?.allUsers || []);
      setIsUsersLoading(false);
    })();
  }, []);

  const submitHandler = async (data: any) => {
    try {
      setIsLoading(true);
      const profilePictureBase64 = (await convertToBase64(
        selectedFiles
      )) as any;
      const body = {
        ...data,
        profilePicture: profilePictureBase64[0]?.url,
      };

      const res = await addUser(body);
      if (res?.statusText === "OK") {
        const newData = {
          ...data,
          id: users?.length + 1,
          _id: res?.data?.user?._id,
          profilePicture: res?.data?.user?.profilePicture,
          status: true,
        };
        const usersCopy = [...users];
        setUsers([...usersCopy, newData]);
        setIsLoading(false);
        setSearchParams("");
        onSuccess(res?.data?.msg);
      }
      setIsLoading(false);
      resetHandler();
    } catch (error) {
      const errorMsg = (error as any)?.response?.data?.msg;
      onError(errorMsg);
      setIsLoading(false);
    }
  };

  const editUserHandler = (row: any, index: number) => {
    const allUsers = [...users];
    const editUserCopy = { ...allUsers[index] };
    setEditUser(editUserCopy?._id);
    setValue("name", editUserCopy?.name);
    setValue("email", editUserCopy?.email);
    setValue("roleName", editUserCopy?.role || editUserCopy?.roleName);
    setValue("profilePicture", editUserCopy?.profilePicture);
    setValue("status", editUserCopy?.status);
    setSelectedFiles([
      {
        preview: editUserCopy?.profilePicture,
        url: editUserCopy?.profilePicture,
      },
    ] as any);
  };

  const updateUserHandler = async () => {
    try {
      setIsLoading(true);
      const allUsers = [...users];
      const index = allUsers.findIndex((user) => user?._id === editUser);
      const editUserCopy = { ...allUsers[index] };
      const updateValues = getValues();
      editUserCopy.name = updateValues?.name;
      editUserCopy.password = updateValues?.password;
      editUserCopy.confirmPassword = updateValues?.confirmPassword;
      editUserCopy.roleName = updateValues?.roleName;
      editUserCopy.status = updateValues?.status;
      const profilePictureBase64 = updateValues?.profilePicture
        ? await convertToBase64(updateValues?.profilePicture)
        : "";

      const newProfilePicture =
        profilePictureBase64 && profilePictureBase64[0]?.url
          ? profilePictureBase64[0]?.url
          : profilePictureBase64;

      editUserCopy.profilePicture = newProfilePicture;
      const res = await updateUser(editUser, {
        name: updateValues?.name,
        roleName: updateValues?.roleName,
        profilePicture: newProfilePicture,
        password: updateValues?.password,
        confirmPassword: updateValues?.confirmPassword,
        status: updateValues.status,
      });

      if (res?.statusText === "OK") {
        allUsers[index] = editUserCopy;
        setUsers(allUsers);
        setIsLoading(false);
        reset();
        setEditUser("");
        onSuccess(res?.data?.msg);
      }
      setIsLoading(false);
    } catch (error) {
      onError((error as any)?.response?.data?.msg);
      console.log("Hello", error);
      setIsLoading(false);
    }
  };

  const deleteUserHandler = async (row: any, index: number) => {
    setDeleteUserRecord({ row, index });
  };

  const onDeleteUserHandler = async () => {
    try {
      const res = await deleteUser(deleteUserRecord?.row?._id);
      if (res?.statusText === "OK") {
        setUsers((prevUsers) => {
          const newUsers = [...prevUsers];
          newUsers.splice(deleteUserRecord?.index, 1);
          return newUsers;
        });
        onSuccess(res?.data?.msg);
      }
      setDeleteUserRecord("");
    } catch (error) {
      const errorMsg = (error as any)?.response?.data?.msg;
      onError(errorMsg);
      setIsLoading(false);
    }
  };

  const resetHandler = () => {
    reset();
    setSelectedFiles([]);
  };

  const transformTableData = (users: any) => {
    const transformedData = users.map((user: any, index: number) => {
      return {
        id: index + 1,
        _id: user?._id,
        name: user?.name,
        email: user?.email,
        role: user?.role,
        status:
          user?.status === true
            ? "Active"
            : user?.status === true
            ? "Inactive"
            : "",
        lastActive: user?.lastActive
          ? format(new Date(user?.lastActive), "yyyy-MM-dd hh:mm a")
          : "",
        profilePicture: user?.profilePicture ? (
          <img src={`${user?.profilePicture}`} height={"34px"} />
        ) : (
          ""
        ),
      };
    });
    return transformedData;
  };
  const transformTableDataCSV = (users: any) => {
    const transformedData = users.map((user: any, index: number) => {
      return {
        id: index + 1,
        name: user?.name,
        email: user?.email,
        role: user?.role,
        status:
          user?.status === true
            ? "Active"
            : user?.status === true
            ? "Inactive"
            : "",
        lastActive: user?.lastActive
          ? format(new Date(user?.lastActive), "yyyy-MM-dd hh:mm a")
          : "",
        profilePicture: user?.profilePicture ? user?.profilePicture : "",
      };
    });
    return transformedData;
  };

  const transformedUsersData = useMemo(
    () => transformTableData(users),
    [users]
  );

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: users?.length,
    },
  ];
  const userColumns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Picture",
      accessor: "profilePicture",
      sort: true,
    },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Email",
      accessor: "email",
      sort: true,
    },
    {
      Header: "Role",
      accessor: "role",
      sort: true,
    },

    {
      Header: "Status",
      accessor: "status",
      sort: true,
    },
    {
      Header: "Last Active",
      accessor: "lastActive",
      sort: true,
    },
  ];

  const roleOptions = [
    { value: "", label: "Select Role" },
    { value: "Admin", label: "Admin" },
    { value: "Sales", label: "Sales" },
    { value: "Customer Support", label: "Customer Support" },
    { value: "Developer", label: "Developer" },
    { value: "QA", label: "QA" },
  ];

  const csvExportHandler = async (users: any) => {
    const dataToBeExported = transformTableDataCSV(users);
    downloadCSV(dataToBeExported, "Users");
  };

  return (
    <>
      {toastComponent}
      {!createUser && !editUser && (
        <div>
          {isUsersLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "calc(80vh - 50px)",
              }}
            >
              <Spinner />
            </div>
          ) : users?.length > 0 ? (
            <>
              <div
                style={{
                  margin: "0px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>User Management</h3>
                {permittedRoles.includes(userRole) && (
                  <div className="d-flex gap-2">
                    <Button onClick={() => csvExportHandler(users)}>
                      {"Export CSV"}
                    </Button>
                    <Button
                      onClick={() => setSearchParams({ createUser: "true" })}
                    >
                      {"Create User"}
                    </Button>
                  </div>
                )}
              </div>
              <div style={{ overflowY: "scroll", height: "87vh" }}>
                <Table
                  columns={userColumns}
                  data={transformedUsersData}
                  pageSize={10}
                  sizePerPageList={sizePerPageList}
                  pagination={true}
                  isSelectable={true}
                  isSortable={true}
                  onDeleteRow={
                    permittedRoles.includes(userRole) && deleteUserHandler
                  }
                  onEditRow={
                    permittedRoles.includes(userRole) && editUserHandler
                  }
                  tableHeight={"tableHeight"}
                  isSearchable={true}
                  pagination2={true}
                />
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center ">
              <div className="d-flex flex-column align-items-center">
                <h1 className="mb-4" style={{ opacity: "0.5" }}>
                  No user
                </h1>
                {permittedRoles.includes(userRole) && (
                  <Button
                    onClick={() => setSearchParams({ createUser: "true" })}
                    disabled={isLoading}
                  >
                    {"Create User"}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {createUser && (
        <div className="">
          <div className="d-flex gap-2 align-items-center">
            <FeatherIcon
              icon={"arrow-left"}
              className="cursor-pointer"
              onClick={() => {
                setSearchParams("");
                reset();
              }}
            />

            <h3 className="d-inline">Create User</h3>
          </div>
          <form
            name="chat-form"
            id="chat-form"
            onSubmit={handleSubmit(submitHandler)}
          >
            <div
              className=""
              style={{
                gap: "20px",
                marginTop: "20px",
                marginBottom: "100px",
              }}
            >
              <FormInput
                type="text"
                name="name"
                label={"Name"}
                register={register}
                errors={errors}
                control={control}
              />
              <FormInput
                type="email"
                name="email"
                label={"Email"}
                register={register}
                errors={errors}
                control={control}
              />

              <div>
                <FormLabel>Role</FormLabel>
                <SelectInput
                  label="Hello"
                  name="roleName"
                  register={register}
                  options={roleOptions}
                  errors={errors}
                ></SelectInput>
              </div>
              <FormInput
                type="password"
                name="password"
                label={"Password"}
                register={register}
                errors={errors}
                control={control}
              />
              <FormInput
                type="password"
                name="confirmPassword"
                label={"Confirm Password"}
                register={register}
                errors={errors}
                control={control}
              />
              <div>
                <FormLabel>Upload Picture</FormLabel>
                <FileUploader
                  setSelectedFiles={setSelectedFiles}
                  selectedFiles={selectedFiles}
                  name={"profilePicture"}
                  setValue={setValue}
                  accept="image/*"
                  errors={errors}
                  isMultiple={false}
                />
              </div>
            </div>
            <div className="pt-2 d-flex justify-content-end gap-2">
              <Button
                onClick={() => {
                  setSearchParams("");
                  reset();
                }}
                style={{ backgroundColor: "rgb(228, 92, 60)", border: "none" }}
                variant={"danger"}
              >
                {"Cancel"}
              </Button>
              <Button type="submit" disabled={isLoading}>
                {"Create"}
              </Button>
            </div>
          </form>
        </div>
      )}

      {editUser && (
        <div>
          <form
            name="chat-form"
            id="chat-form"
            onSubmit={handleSubmit(updateUserHandler)}
          >
            <div className="d-flex gap-2 align-items-center">
              <FeatherIcon
                icon={"arrow-left"}
                className="cursor-pointer"
                onClick={() => {
                  setEditUser("");
                  reset();
                }}
              />
              <h3 className="d-inline">Edit User</h3>
            </div>
            <div
              className=" "
              style={{
                gap: "20px",
                marginTop: "20px",
                marginBottom: "100px",
              }}
            >
              <FormInput
                type="text"
                name="name"
                label={"Name"}
                register={register}
                errors={errors}
                control={control}
                autoComplete="off"
              />
              <FormInput
                type="email"
                name="email"
                label={"Email"}
                register={register}
                errors={errors}
                control={control}
                autoComplete="off"
              />

              <div>
                <FormLabel>Role</FormLabel>

                <SelectInput
                  label="Hello"
                  name="roleName"
                  register={register}
                  options={roleOptions}
                  errors={errors}
                  autoComplete="off"
                ></SelectInput>
              </div>

              <FormInput
                type="password"
                name="password"
                label={"Password"}
                register={register}
                errors={errors}
                control={control}
                autoComplete="off"
              />
              <FormInput
                type="password"
                name="confirmPassword"
                label={"Confirm Password"}
                register={register}
                errors={errors}
                control={control}
                autoComplete="off"
              />
              <div>
                <FormLabel>Upload Picture</FormLabel>
                <FileUploader
                  setSelectedFiles={setSelectedFiles}
                  selectedFiles={selectedFiles}
                  name={"profilePicture"}
                  setValue={setValue}
                  accept="image/*"
                  errors={errors}
                  isMultiple={false}
                />
              </div>
              <div>
                <Switch name={"status"} setValue={setValue} watch={watch} />
              </div>
            </div>

            <div className="pt-2 d-flex justify-content-end gap-2">
              <Button
                onClick={() => {
                  setEditUser("");
                  reset();
                }}
                variant={"danger"}
                style={{ backgroundColor: "rgb(228, 92, 60)", border: "none" }}
              >
                {"Cancel"}
              </Button>
              <Button type="submit" disabled={isLoading}>
                {"Update"}
              </Button>
            </div>
          </form>
        </div>
      )}

      {deleteUserRecord && (
        <DeleteModal
          showModal={deleteUserRecord}
          setShowModal={setDeleteUserRecord}
          label="Are you sure to delete user"
          onDelete={onDeleteUserHandler}
        />
      )}
    </>
  );
};

export default UserAccount;
