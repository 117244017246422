import React, { useEffect, useState } from "react";

import { compareAsc, format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import Table from "../../../../components/Table";
import "../workspaces.scss";
import { getWidgets } from "../../api/workspaces";
import useToaster from "../../toast";

const Workspaces = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);
  const [widgets, setWidgets] = useState<any[]>([]);
  const [widgetsCount, setWidgetsCount] = useState(0);
  const { onSuccess, onError, toastComponent } = useToaster();
  const workspaceId = searchParams.get("workspace");

  useEffect(() => {
    (async () => {
      try {
        const body = {
          lastAccessedWorkspace: workspaceId,
        };
        setIsWidgetLoading(true);
        const res = await getWidgets(body);
        setWidgets(res?.data?.feedbackWidgets);
        setWidgetsCount(res?.data?.count);
        setIsWidgetLoading(false);
      } catch (error) {
        onError((error as any)?.response?.data?.msg || "Error occurred");
        setIsWidgetLoading(false);
        console.log(error);
      }
    })();
  }, []);

  const transformTableData = (widgets: any) => {
    const transformedData = widgets.map((widget: any, index: number) => {
      return {
        id: index + 1,
        _id: widget?._id,
        name: widget?.widgetTitle,
        projectName: widget?.projectId?.name,
        updatedAt: format(new Date(widget?.updatedAt), "yyyy-MM-dd hh:mm a"),
        widgetPosition: widget?.widgetPosition,
        textColor: widget?.textColor,
        widgetColor: widget?.widgetButtonColor,
      };
    });
    return transformedData;
  };

  console.log({ widgets });

  const transformedUsersData = transformTableData(widgets);

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: widgets?.length,
    },
  ];
  const userColumns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Widget name",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Project name",
      accessor: "projectName",
      sort: true,
    },
    {
      Header: "Updated at",
      accessor: "updatedAt",
      sort: true,
    },
    {
      Header: "Position",
      accessor: "widgetPosition",
      sort: true,
    },
    {
      Header: "Widget color",
      accessor: "widgetColor",
      sort: true,
    },
    {
      Header: "Text color",
      accessor: "textColor",
      sort: true,
    },
  ];

  return (
    <>
      {toastComponent}
      {isWidgetLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "calc(60vh - 50px)",
          }}
        >
          <Spinner />
        </div>
      ) : widgetsCount > 0 ? (
        <div>
          <Table
            columns={userColumns}
            data={transformedUsersData}
            sizePerPageList={sizePerPageList}
            isSelectable={true}
            isSortable={true}
          />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "calc(60vh - 50px)",
          }}
        >
          <h4>No Widgets</h4>
        </div>
      )}
    </>
  );
};

export default Workspaces;
