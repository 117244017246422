import React, { InputHTMLAttributes } from "react";
import { Form } from "react-bootstrap";

interface SelectInputProps extends InputHTMLAttributes<HTMLSelectElement> {
  type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: any;
  comp?: any;
  rows?: number;
  className?: string;
  refCallback?: (ref: HTMLInputElement) => void;
  options?: Array<{ value: string | number; label: string }>;
}

const SelectInput: React.FC<SelectInputProps> = ({
  type,
  label,
  name,
  placeholder,
  register,
  errors,
  comp,
  rows,
  className,
  refCallback,
  options = [],
  ...otherProps
}) => {
  return (
    <>
      <Form.Select
        type={type}
        name={name}
        id={name}
        ref={(r: HTMLInputElement) => {
          if (refCallback) refCallback(r);
        }}
        className={className}
        isInvalid={errors && errors[name] ? true : false}
        {...(register ? register(name) : {})}
        {...otherProps}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>

      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid">
          {errors[name]["message"]}
        </Form.Control.Feedback>
      ) : null}
    </>
  );
};

export default SelectInput;
