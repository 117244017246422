import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import style from "./workspace.module.scss";
import Tabs from "../../../../components/tabs/tabs";
import Overview from "./overview";
import Users from "./users";
import Billing from "./billing";
import Widgets from "./widgets";

const Workspace = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const workspaceId = searchParams.get("workspace") || "";
  const workspaceName = searchParams.get("name") || "";
  const active = searchParams.get("active");

  const [activeTab, setActiveTab] = useState<number>(0);

  let user;
  const localUser = localStorage?.getItem("user");
  if (localUser) {
    user = JSON.parse(localUser);
  }

  useEffect(() => {
    if (active) {
      setActiveTab(+active);
    }
  }, []);

  useEffect(() => {
    if (activeTab >= 0) {
      setSearchParams({
        workspace: workspaceId,
        name: workspaceName,
        active: activeTab.toString(),
      });
    }
  }, [activeTab]);

  const pages = [
    ...(["Admin", "Super Admin"].includes(user?.role)
      ? [
          {
            id: 0,
            tabTitle: "Overview",
            content: activeTab === 0 && <Overview />,
          },
        ]
      : []),

    ...(["Admin", "Super Admin", "Sale", "Customer Support"].includes(
      user?.role
    )
      ? [
          {
            id: 1,
            tabTitle: "Users",
            content: activeTab === 1 && <Users />,
          },
        ]
      : []),

    ...(["Admin", "Super Admin", "Sale"].includes(user?.role)
      ? [
          {
            id: 2,
            tabTitle: "Billing",
            content: activeTab === 2 && <Billing />,
          },
        ]
      : []),
    {
      id: 3,
      tabTitle: "Widgets",
      content: activeTab === 3 && <Widgets />,
    },
  ];

  return (
    <div className={style.main}>
      <div className={style.header}>
        <FeatherIcon
          icon={"arrow-left"}
          className="cursor-pointer"
          onClick={() => {
            setSearchParams({});
          }}
        />
        <h3>{workspaceName}</h3>
      </div>
      <Tabs
        pages={pages?.filter((x) => x.tabTitle)}
        activeTab={activeTab}
        setActiveTab={(e: any) => {
          setActiveTab(e);
        }}
      />
    </div>
  );
};

export default Workspace;
