import React, { useEffect, useState } from "react";

import { compareAsc, format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import Table from "../../../../components/Table";
import "../workspaces.scss";
import FeatherIcon from "feather-icons-react";
import OverViewItem from "../../../../components/OverViewItem";
import {
  cancelSubscription,
  changeSubscriptionAuto,
  changeSubscriptionManually,
  getSingleWorkspaceBilling,
} from "../../api/workspaces";
import useToaster from "../../toast";
import { Button } from "react-bootstrap";
import DeleteModal from "../../delete-modal";
import FeatherIcons from "../../../icons/Feather";
import ChangeSubscriptionModal from "./billingModal";
import Permissions from "../../../../components/Permissions";

const Billing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { onSuccess, onError, toastComponent } = useToaster();
  const [data, setData] = useState<any>([]);
  const [isCancelSubscription, setIsCancelSubscription] = useState(false);
  const [isChangeSubscription, setIsChangeSubscription] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const workspaceId = searchParams.get("workspace");

  useEffect(() => {
    (async () => {
      try {
        const body = {
          lastAccessedWorkspace: workspaceId,
        };
        setIsDataLoading(true);
        const res = await getSingleWorkspaceBilling(body);
        setData(res?.data?.data);
        setIsDataLoading(false);
      } catch (error) {
        onError((error as any)?.response?.data?.msg || "Error occurred");
        setIsDataLoading(false);
      }
    })();
  }, []);

  const transformTableData = (invoiceHistory: any) => {
    const transformedData = invoiceHistory.map(
      (transaction: any, index: number) => {
        return {
          id: index + 1,
          invoiceNumber: transaction?.invoiceNumber,
          description: transaction?.description,
          date: transaction?.date,
          amount: transaction?.amount,
          invoiceLink: (
            <a href={`${transaction?.invoiceLink}`} target="_black">
              <FeatherIcon icon="download-cloud" />
            </a>
          ),
        };
      }
    );
    return transformedData;
  };

  const transformedUsersData = transformTableData(
    data?.invoiceData?.invoiceHistory || []
  );

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: data?.invoiceData?.invoiceHistory?.length,
    },
  ];
  const invoiceColumns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Transaction ID",
      accessor: "invoiceNumber",
      sort: true,
    },
    {
      Header: "Description",
      accessor: "description",
      sort: true,
    },
    {
      Header: "Transaction Date",
      accessor: "date",
      sort: true,
    },
    {
      Header: "Amount",
      accessor: "amount",
      sort: true,
    },
    {
      Header: "Invoice",
      accessor: "invoiceLink",
    },
  ];

  const subscriptionCancelHandler = async () => {
    try {
      const body = {
        lastAccessedWorkspace: workspaceId,
      };
      const res = await cancelSubscription(body);
      if (res?.statusText === "OK") {
        setIsCancelSubscription(false);
        onSuccess(res?.data?.msg);
      }
    } catch (error) {
      onError((error as any)?.response?.data?.msg);
      setIsCancelSubscription(false);
      console.log(error);
    }
  };

  const changePlanManually = async (data: any) => {
    if (data?.type === "manual") {
      try {
        const { type, ...newData } = data;
        const body = {
          lastAccessedWorkspace: workspaceId,
          ...newData,
        };
        setIsloading(true);
        const res = await changeSubscriptionManually(body);
        if (res?.statusText === "OK") {
          setIsChangeSubscription("");
          onSuccess(res?.data?.msg);
        }
        setIsloading(false);
      } catch (error) {
        onError((error as any)?.response?.data?.msg);
        setIsChangeSubscription("");
        console.log(error);
        setIsloading(false);
      }
    } else {
      try {
        const { type, ...newData } = data;
        const body = {
          lastAccessedWorkspace: workspaceId,
          ...newData,
        };
        setIsloading(true);
        const res = await changeSubscriptionAuto(body);
        if (res?.statusText === "OK") {
          setIsChangeSubscription("");
          onSuccess(res?.data?.msg);
        }
        setIsloading(false);
      } catch (error) {
        onError((error as any)?.response?.data?.msg);
        setIsChangeSubscription("");
        console.log(error);
        setIsloading(false);
      }
    }
  };

  return (
    <>
      {toastComponent}

      {isDataLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "calc(60vh - 50px)",
          }}
        >
          <Spinner />
        </div>
      ) : data?.invoiceData ? (
        <div>
          <Permissions roles={["Admin", "Super Admin"]}>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2">
                <Button
                  variant={"primary"}
                  style={{
                    backgroundColor: "#041c54",
                    border: "none",
                  }}
                  onClick={() => setIsChangeSubscription("Upgrade")}
                >
                  Upgrade subscription
                </Button>
                <Button
                  variant={"primary"}
                  style={{
                    backgroundColor: "#041c54",
                    border: "none",
                  }}
                  onClick={() => setIsChangeSubscription("Downgrade")}
                >
                  Downgrade subscription
                </Button>
              </div>
              {data?.plan !== "Free" ? (
                <Button
                  variant={"danger"}
                  className="mx-2"
                  style={{
                    backgroundColor: "#e45c3c",
                    border: "none",
                  }}
                  onClick={() => setIsCancelSubscription(true)}
                >
                  Cancel Subscription
                </Button>
              ) : (
                <></>
              )}
            </div>
          </Permissions>

          <div
            style={{
              display: "flex",
            }}
          >
            <OverViewItem
              stats={`${data?.plan}`}
              title={"Subscription plan"}
              icon={"credit-card"}
              iconClass={"icon-lg"}
              backClass="billingCards"
            />
            {data?.invoiceData?.upcomingInvoice?.date && (
              <OverViewItem
                stats={`${data?.invoiceData?.upcomingInvoice?.date}`}
                title={"Next Billing Date"}
                icon={"clock"}
                iconClass={"icon-lg"}
                backClass="billingCards"
              />
            )}
            {data?.seatsAvailable && (
              <OverViewItem
                stats={`${data?.seatsAvailable}`}
                title={"Users"}
                icon={"users"}
                iconClass={"icon-lg"}
                backClass="billingCards"
              />
            )}

            {data?.invoiceData?.upcomingInvoice?.amount && (
              <OverViewItem
                stats={`${data?.invoiceData?.upcomingInvoice?.amount}`}
                title={"Upcoming Invoice Amount"}
                icon={"dollar-sign"}
                iconClass={"icon-lg"}
                backClass="billingCards"
              />
            )}
          </div>

          <Table
            columns={invoiceColumns}
            data={transformedUsersData}
            sizePerPageList={sizePerPageList}
            isSelectable={true}
            isSortable={true}
          />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "calc(60vh - 50px)",
          }}
        >
          <h4>No Transaction</h4>
        </div>
      )}

      {isCancelSubscription && (
        <DeleteModal
          setShowModal={setIsCancelSubscription}
          showModal={isCancelSubscription}
          onDelete={subscriptionCancelHandler}
          title={"Cancel Subscription"}
          subTitle={"Are you sure to cancel the subscription"}
          submitBtnText={"Yes, Cancel"}
          cancelBtnText={"Discard"}
        />
      )}
      {isChangeSubscription && (
        <ChangeSubscriptionModal
          showModal={!!isChangeSubscription}
          setShowModal={setIsChangeSubscription}
          title={`${isChangeSubscription} Subscription`}
          submitBtnText={isChangeSubscription}
          onSubmit={changePlanManually}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default Billing;
