import React from "react";
import { Navigate } from "react-router-dom";

const Root = () => {
  const token = localStorage.getItem("token");
  let user;
  const localUser = localStorage?.getItem("user");
  if (localUser) {
    user = JSON.parse(localUser);
  }
  const url = token && user?.name ? "/dashboard" : "/login";
  return (
    <div>
      <Navigate to={url} />
    </div>
  );
};

export default Root;
