import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Toast,
  Form,
  Modal,
  FormLabel,
} from "react-bootstrap";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { FormInput } from "../../../../../components";

import SelectInput from "../../../../../components/SelectInput";

const ChangeSubscriptionModal = ({
  showModal,
  setShowModal,
  title,
  subTitle,
  onSubmit,
  cancelBtnText,
  submitBtnText,
  handleClose,
  isLoading,
}: any) => {
  const handleCloseInner = () => {
    setShowModal("");
  };
  const handleClose2 = handleClose ? handleClose : handleCloseInner;

  interface changeSubscription {
    plan: string;
    seatsCount: number;
    planPeriod: string;
  }

  const schemaResolver = yupResolver(
    yup.object().shape({
      plan: yup.string().required("Please select a plan"),
      seatsCount: yup.number().required("Please enter seats number"),
      planPeriod: yup.string().required("Please select a plan period"),
    })
  );
  const methods = useForm<changeSubscription>({
    resolver: schemaResolver,
  });

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = methods;

  const submitHandler = (data: changeSubscription) => {
    onSubmit(data);
  };

  const planPeriodOptions: any = [
    { label: "Select Plan Period", value: "" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
  ];
  const planOptions = [
    { label: "Select Plan", value: "" },
    { label: "Basic", value: "Basic" },
    { label: "Premium", value: "Premium" },
  ];

  return (
    <>
      <Modal show={showModal} onHide={handleClose2} size={"lg"}>
        <Modal.Header onHide={handleClose2} closeButton>
          <Modal.Title as="h3">{title || "Change Subscription"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(submitHandler)} id="changeSubscription">
            <div>
              <FormLabel>Upgrade Type:</FormLabel>
              <div style={{ display: "flex", gap: "20px" }}>
                <label htmlFor="manual">
                  <FormInput
                    id="manual"
                    type="radio"
                    name="type"
                    label={"Manual"}
                    register={register}
                    errors={errors}
                    control={control}
                    value={"manual"}
                    defaultChecked
                  />
                </label>
                <label htmlFor="auto">
                  <FormInput
                    id="auto"
                    type="radio"
                    name="type"
                    label={"Auto"}
                    register={register}
                    errors={errors}
                    control={control}
                    value={"auto"}
                  />
                </label>
              </div>
            </div>
            <div>
              <FormLabel>Plan</FormLabel>
              <SelectInput
                label="Plan"
                name="plan"
                register={register}
                options={planOptions}
                errors={errors}
              ></SelectInput>
            </div>
            <div>
              <FormLabel>Plan Period</FormLabel>
              <SelectInput
                label="Plan Period"
                name="planPeriod"
                register={register}
                options={planPeriodOptions}
                errors={errors}
              ></SelectInput>
            </div>
            <FormInput
              type="number"
              name="seatsCount"
              label={"Seat Number"}
              register={register}
              errors={errors}
              control={control}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={handleClose2}
            style={{ border: "2px solid #041c54" }}
          >
            {cancelBtnText || "Discard"}
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="changeSubscription"
            disabled={isLoading}
          >
            {submitBtnText || "Change"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ChangeSubscriptionModal;
