import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Toast,
  Form,
  FormLabel,
} from "react-bootstrap";

import { compareAsc, format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import Table from "../../../components/Table";
import "./workspaces.scss";
import useToaster from "../toast";
import Workspace from "./workspace/workspace";
import { deleteAWorkspace, getAllWorkspaces } from "../api/workspaces";
import DeleteModal from "../delete-modal";
import DelModalConfirmation from "./delete-modal";
import { downloadCSV } from "../utils/fileHandler";

const Workspaces = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isWorkspacesLoading, setIsWorkspacesLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState<any[]>([]);
  const workspaceId = searchParams.get("workspace");
  const [workspaceCount, setWorkspaceCount] = useState<number>(0);
  const { onSuccess, onError, toastComponent } = useToaster();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationDataLoading, setPaginationDataLoading] = useState(false);
  const [deleteWorkspace, setDeleteWorkspace] = useState<any>({});
  const [isDeletingWorkspace, setIsDeletingWorkspace] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const totalPages = Math.ceil(workspaceCount / perPage);

  useEffect(() => {
    setCurrentPage(1);
    onPageChange(1);
  }, [perPage]);

  useEffect(() => {
    (async () => {
      try {
        setIsWorkspacesLoading(true);
        const res = await getAllWorkspaces(perPage, currentPage);
        setWorkspaces(res?.data?.workspaces || []);
        setWorkspaceCount(res?.data?.count || 0);
        setIsWorkspacesLoading(false);
      } catch (error) {
        onError((error as any)?.response?.data?.msg || "Error occurred");
        setIsWorkspacesLoading(false);
        console.log(error);
      }
    })();
  }, []);

  const onPageChange = async (updatedPage: any) => {
    try {
      setCurrentPage(updatedPage);
      setPaginationDataLoading(true);
      const res =
        workspaceCount === perPage
          ? await getAllWorkspaces(0, 0)
          : await getAllWorkspaces(perPage, updatedPage);
      setWorkspaces(res?.data?.workspaces || []);
      setWorkspaceCount(res?.data?.count || 0);
      setPaginationDataLoading(false);
    } catch (error) {
      onError((error as any)?.response?.data?.msg || "Error occurred");
      setPaginationDataLoading(false);
      console.log(error);
    }
  };

  const transformTableData = (workspaces: any) => {
    const transformedData = workspaces?.map((workspace: any, index: number) => {
      return {
        id: perPage * (currentPage - 1) + index + 1,
        _id: workspace?._id,
        name: workspace?.name,
        plan: workspace?.plan,
        avatar: workspace?.avatar ? (
          <img src={`${workspace?.avatar}`} height={"34px"} />
        ) : (
          ""
        ),
        darkMode: workspace?.darkMode ? "Enabled" : "Disabled",
        totalStorage: `${workspace?.s3totalStorageInGb} Gbs`,
        members: workspace?.members?.map((member: any) => member?.name),
        defaultStorage: workspace?.defaultStorage,
        storageUsed:
          workspace?.occupiedS3Space?.totalSizeInMb < 1000
            ? `${Math.floor(workspace?.occupiedS3Space?.totalSizeInMb)} Mbs`
            : `${workspace?.occupiedS3Space?.totalSizeInGb} Gbs`,
        status:
          workspace?.status === true
            ? "Active"
            : workspace?.status === true
            ? "Inactive"
            : "",
        createdAt: format(new Date(workspace?.createdAt), "yyyy-MM-dd hh:mm a"),
        profilePicture: workspace?.profilePicture ? (
          <img src={`${workspace?.profilePicture}`} height={"34px"} />
        ) : (
          ""
        ),
      };
    });
    return transformedData;
  };

  const transformTableDataCSV = (workspaces: any) => {
    const transformedData = workspaces?.map((workspace: any, index: number) => {
      return {
        id: perPage * (currentPage - 1) + index + 1,
        name: workspace?.name,
        plan: workspace?.plan,
        avatar: workspace?.avatar ? workspace?.avatar : "",
        darkMode: workspace?.darkMode ? "Enabled" : "Disabled",
        totalStorage: `${workspace?.s3totalStorageInGb} Gbs`,
        members: workspace?.members?.map((member: any) => member?.name),
        defaultStorage: workspace?.defaultStorage,
        storageUsed:
          workspace?.occupiedS3Space?.totalSizeInMb < 1000
            ? `${Math.floor(workspace?.occupiedS3Space?.totalSizeInMb)} Mbs`
            : `${workspace?.occupiedS3Space?.totalSizeInGb} Gbs`,
        status:
          workspace?.status === true
            ? "Active"
            : workspace?.status === true
            ? "Inactive"
            : "",
        createdAt: format(new Date(workspace?.createdAt), "yyyy-MM-dd hh:mm a"),
        profilePicture: workspace?.profilePicture
          ? workspace?.profilePicture
          : "",
      };
    });
    return transformedData;
  };

  const transformedUsersData = useMemo(
    () => transformTableData(workspaces),
    [workspaces]
  );

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: workspaceCount,
    },
  ];
  const workspaceColumns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Avatar",
      accessor: "avatar",
    },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Plan",
      accessor: "plan",
      sort: true,
    },
    {
      Header: "Members",
      accessor: "members",
      sort: true,
    },
    {
      Header: "Default Storage",
      accessor: "defaultStorage",
      sort: true,
    },
    {
      Header: "Storage Used",
      accessor: "storageUsed",
      sort: true,
    },
    {
      Header: "Total Storage",
      accessor: "totalStorage",
      sort: true,
    },
    {
      Header: "Created at",
      accessor: "createdAt",
      sort: true,
    },
  ];

  const rowClickHandler = (row: any) => {
    const workspaceId = row?.original?._id;
    const workspaceName = row?.original?.name;
    setSearchParams({ workspace: `${workspaceId}`, name: workspaceName });
    // setSearchParams({
    //   workspace: `6526363838a45b7e2cc15854`,
    //   name: workspaceName,
    // });
  };

  const onDeleteWorkspace = (row: any, index: any) => {
    setDeleteWorkspace({ row, index });
  };

  const deleteWorkspaceHandler = async () => {
    try {
      const body = {
        lastAccessedWorkspace: deleteWorkspace?.row?._id,
        name: deleteWorkspace?.row?.name,
      };
      setIsDeletingWorkspace(true);
      const res = await deleteAWorkspace(body);
      if (res?.statusText === "OK") {
        setWorkspaces((preWorkspaces) => {
          const newWorkspaces = [...preWorkspaces];
          newWorkspaces.splice(deleteWorkspace?.index, 1);
          return newWorkspaces;
        });
        setWorkspaceCount((prevCount) => prevCount - 1);
        setDeleteWorkspace({});
        onSuccess(res?.data?.msg);
        setIsDeletingWorkspace(false);
      }
    } catch (error) {
      setPaginationDataLoading(false);
      onError((error as any)?.response?.data?.msg);
      setIsDeletingWorkspace(false);
      console.log(error);
    }
  };
  const csvExportHandler = async (workspaces: any) => {
    const dataToBeExported = transformTableDataCSV(workspaces);
    downloadCSV(dataToBeExported, "workspaces");
  };

  return (
    <>
      {toastComponent}
      {!workspaceId && (
        <div>
          <div className="d-flex justify-content-between">
            <h3>{`Workspaces (${workspaceCount})`}</h3>
            <Button onClick={() => csvExportHandler(workspaces)}>
              Export CSV
            </Button>
          </div>
          <div style={{ overflowY: "scroll", height: "87vh" }}>
            {isWorkspacesLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "calc(80vh - 50px)",
                }}
              >
                <Spinner />
              </div>
            ) : workspaces?.length > 0 ? (
              <>
                {deleteWorkspace?.row && (
                  <DelModalConfirmation
                    showModal={!!deleteWorkspace}
                    title={"Delete Workspace"}
                    subTitle={""}
                    onDelete={deleteWorkspaceHandler}
                    cancelBtnText={"Cancel"}
                    submitBtnText={"Delete"}
                    confirmationText={deleteWorkspace?.row?.name}
                    handleClose={() => setDeleteWorkspace({})}
                    isLoading={isDeletingWorkspace}
                  />
                )}
                <Table
                  columns={workspaceColumns}
                  data={transformedUsersData}
                  sizePerPageList={sizePerPageList}
                  isSelectable={true}
                  isSortable={true}
                  onRowClick={rowClickHandler}
                  trowClass={"trowClass"}
                  paginationWithApi={{
                    onPageChange,
                    totalPages,
                    currentPage,
                    isLoading: paginationDataLoading,
                  }}
                  onDeleteRow={onDeleteWorkspace}
                  isSearchable={true}
                  pagination={true}
                  setPerPage={setPerPage}
                  perPage={perPage}
                  pageSize={perPage}
                />
              </>
            ) : (
              <div className="d-flex justify-content-center ">
                <div className="d-flex flex-column align-items-center">
                  <h1 className="mb-4" style={{ opacity: "0.5" }}>
                    No Workspace
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {workspaceId && <Workspace />}
    </>
  );
};

export default Workspaces;

const staticWorkspaces = [
  {
    _id: "664c79546d9b0357a2b9f3af",
    name: "Workspace",
    owner: {
      _id: "664c79166d9b0357a2b9f3a0",
      name: "deded",
      email: "xatot67074@mcatag.com",
    },
    isDeleted: false,
    orgSize: "Just Me",
    source: "Search Engine",
    plan: "Free",
    seatsAvailable: 5,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-21T10:37:08.540Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "664c77b16d9b0357a2b9f35d",
    name: "undefined's Workspace",
    owner: {
      _id: "664c77506d9b0357a2b9f34e",
      name: "qwertyu",
      email: "homov17475@qiradio.com",
    },
    isDeleted: false,
    orgSize: "Just Me",
    source: "Search Engine",
    plan: "Free",
    seatsAvailable: 5,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-21T10:30:09.868Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "664c73506d9b0357a2b9f2f6",
    name: "testing something's Workspace",
    owner: {
      _id: "664c732d6d9b0357a2b9f2ea",
      name: "testing something",
      email: "ligar83890@lucvu.com",
    },
    isDeleted: false,
    orgSize: "Just Me",
    source: "Search Engine",
    plan: "Free",
    seatsAvailable: 5,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-21T10:11:28.541Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "664b67ad11225370d0c4138e",
    name: "testing's Workspace",
    owner: {
      _id: "664b635011225370d0c41382",
      name: "testing",
      email: "cegebah177@mcatag.com",
    },
    isDeleted: false,
    orgSize: "Just Me",
    source: "Search Engine",
    plan: "Free",
    seatsAvailable: 5,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-20T15:09:33.801Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "664aff7e4b186898b7ba7fb1",
    name: "Email testing's Workspace",
    owner: {
      _id: "664aff494b186898b7ba7f9d",
      name: "Email testing",
      email: "nejaxew174@fincainc.com",
    },
    isDeleted: false,
    orgSize: "Just Me",
    source: "Search Engine",
    plan: "Basic",
    seatsAvailable: 2,
    requestedSeats: 2,
    paymentDeclined: false,
    subscriptionDeleted: false,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-20T07:45:02.816Z",
    planPeriod: "Monthly",
    stripeCustomerId: "cus_Q8iKB4v9KkQZWm",
    subscriptionId: "sub_1PIQuEAwcQNypKuCnCOSoev2",
    trialEnd: "2024-06-19T07:45:18.261Z",
    trialStart: "2024-05-20T07:45:18.261Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "664ae52fa6604ae67c9fc128",
    name: "Deleting my WS",
    owner: {
      _id: "6646fb96f243bc0850e00fdb",
      name: "Cross Check",
      email: "crosscheck.testing@gmail.com",
    },
    isDeleted: false,
    orgSize: "Just Me",
    source: "Search Engine",
    plan: "Basic",
    seatsAvailable: 2,
    requestedSeats: 2,
    paymentDeclined: false,
    subscriptionDeleted: false,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-20T05:52:47.074Z",
    planPeriod: "Monthly",
    stripeCustomerId: "cus_Q8gWwHOB1Jm3iO",
    subscriptionId: "sub_1PIP9fAwcQNypKuCtMDhHsa4",
    trialEnd: "2024-06-19T05:53:07.904Z",
    trialStart: "2024-05-20T05:53:07.901Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "6647314df243bc0850e0caff",
    name: "D's Workspace 78675",
    owner: {
      _id: "664730e4f243bc0850e0caa0",
      name: "D",
      email: "yomayo9420@huleos.com",
    },
    isDeleted: false,
    orgSize: "6-10",
    source: "Facebook / Instagram",
    plan: "Basic",
    seatsAvailable: 1,
    requestedSeats: 1,
    paymentDeclined: false,
    subscriptionDeleted: false,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-17T10:28:29.606Z",
    planPeriod: "Monthly",
    stripeCustomerId: "cus_Q7dIPecQWUL7Lr",
    subscriptionId: "sub_1PHO1qAwcQNypKuC9cGmFrV2",
    trialEnd: "2024-06-16T10:28:50.566Z",
    trialStart: "2024-05-17T10:28:50.566Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "66473123f243bc0850e0caac",
    name: "D's Workspace",
    owner: {
      _id: "664730e4f243bc0850e0caa0",
      name: "D",
      email: "yomayo9420@huleos.com",
    },
    isDeleted: false,
    orgSize: "Just Me",
    source: "Search Engine",
    plan: "Free",
    seatsAvailable: 5,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-17T10:27:47.501Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "66470cdcf243bc0850e09ef3",
    name: "Paying's Workspace",
    owner: {
      _id: "66470b04f243bc0850e08c68",
      name: "Paying",
      email: "wehope8061@rencr.com",
    },
    isDeleted: false,
    orgSize: "Just Me",
    source: "Search Engine",
    plan: "Free",
    seatsAvailable: 5,
    requestedSeats: 2,
    paymentDeclined: true,
    subscriptionDeleted: false,
    attemptingPlan: "Premium",
    attemptingPlanPeriod: "Yearly",
    attemptingPlanId: "price_1NwX3PAwcQNypKuCuxXkybBy",
    ackPaymentDecline: false,
    attemptingUpgrade: true,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-17T07:53:00.495Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
  {
    _id: "66470bebf243bc0850e09e10",
    name: "Paying's Workspace",
    owner: {
      _id: "66470b04f243bc0850e08c68",
      name: "Paying",
      email: "wehope8061@rencr.com",
    },
    isDeleted: false,
    orgSize: "11-25",
    source: "Youtube",
    plan: "Premium",
    seatsAvailable: 2,
    requestedSeats: 2,
    paymentDeclined: false,
    subscriptionDeleted: false,
    attemptingUpgrade: false,
    attemptingSeatsChange: false,
    trialPeriodExpired: false,
    defaultStorage: "S3",
    invitees: [],
    createdAt: "2024-05-17T07:48:59.715Z",
    planPeriod: "Yearly",
    stripeCustomerId: "cus_Q7aivEBh3lktOr",
    subscriptionId: "sub_1PHLXsAwcQNypKuCOlMXc8w5",
    trialEnd: "2024-06-16T07:49:45.656Z",
    trialStart: "2024-05-17T07:49:45.656Z",
    occupiedS3Space: {
      totalSizeInBytes: 0,
      totalSizeInKb: 0,
      totalSizeInMb: 0,
      totalSizeInGb: 0,
    },
    googleDriveAccessToken: false,
    googleDriveRefreshToken: false,
    oneDriveAccessToken: false,
    oneDriveRefreshToken: false,
  },
];
