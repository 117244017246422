import axios from "axios";
const base = process.env.REACT_APP_BACKEND_DOMAIN;

export const getAllWorkspaces = async (perPage: number, page: number) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/crosscheck-workspaces?page=${page}&perPage=${perPage}`,
    {},
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};

export const getWorkspaceAnalytics = async (body: any) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/single-workspace-admin-analytics-widget`,
    body,
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};
export const getWidgets = async (body: any) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/single-workspace-feedback-widgets`,
    body,
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};

export const getSingleWorkspaceUsers = async (
  body: any,
  perPage: number,
  page: number
) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/single-workspace-users?page=${page}&perPage=${perPage}`,
    body,
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};

export const getSingleWorkspaceBilling = async (body: any) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/single-workspace-billing-section`,
    body,
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};

export const deleteAWorkspace = async (body: any) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(`${base}/api/admin-portal/delete-workspace`, {
    headers: { Authorization: `${token}` },
    data: body,
  });
  return res;
};

export const getSingleWorkspaceInvitees = async (body: any) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/single-workspace-invitees`,
    body,
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};

export const cancelSubscription = async (body: any) => {
  const token = localStorage.getItem("token");
  const res = await axios.put(
    `${base}/api/admin-portal/cancel-subscription`,
    body,
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};

export const changeSubscriptionManually = async (body: any) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/update-subscription-manual`,
    body,
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};

export const changeSubscriptionAuto = async (body: any) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${base}/api/admin-portal/update-subscription`,
    body,
    {
      headers: { Authorization: `${token}` },
    }
  );
  return res;
};
