const convertBase64File = (file: any) => {
  if (!file) return "";
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export async function convertToBase64(files: any) {
  const processedFiles = [];

  if (typeof files === "string") {
    return files;
  }

  for (const file of files) {
    if (file?.url) {
      const { _id, ...fileWithoutId } = file;
      processedFiles.push(fileWithoutId);
    } else {
      const base64Data = await convertBase64File(file);
      processedFiles.push({ name: file.name, url: base64Data });
    }
  }

  return processedFiles;
}
