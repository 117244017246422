import React, { useEffect, useState } from "react";

import DonutChart from "../../../charts/DonutChart";
import PieChart from "../../../charts/PieChart";
import RadialBarMultipleChart from "../../../charts/RadialBarMultipleChart";
import styles from "./workspace.module.scss";
import OverViewItem from "../../../../components/OverViewItem";
import { Card, Dropdown } from "react-bootstrap";
import FeatherIcons from "../../../icons/Feather";
import { useSearchParams } from "react-router-dom";
import { getWorkspaceAnalytics } from "../../api/workspaces";
import Spinner from "../../../../components/Spinner";
import useToaster from "../../toast";

const Overview = () => {
  const [data, setData] = useState<any>();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onSuccess, onError, toastComponent } = useToaster();
  const workspaceId = searchParams.get("workspace");

  useEffect(() => {
    (async () => {
      try {
        setIsDataLoading(true);
        const body = {
          lastAccessedWorkspace: workspaceId,
        };
        const res = await getWorkspaceAnalytics(body);
        setData(res?.data?.data);
        setIsDataLoading(false);
      } catch (error) {
        onError((error as any)?.response?.data?.msg || "Error occurred");
        setIsDataLoading(false);
        console.log(error);
      }
    })();
  }, []);

  console.log({ data });

  return (
    <>
      {toastComponent}
      {isDataLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "calc(60vh - 50px)",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div className={styles.listing}>
          <Card>
            <Card.Body className="p-0">
              <OverViewItem
                stats={`${data?.users}`}
                title={"Users"}
                icon={"users"}
                iconClass={"icon-lg"}
              />

              <OverViewItem
                stats={`${data?.projects ?? ""}`}
                title={"projects"}
                icon={"briefcase"}
                iconClass={"icon-lg"}
              />

              <OverViewItem
                stats={`${data?.seatsAvailable}`}
                title={"Seats Available"}
                icon={"user-plus"}
                iconClass={"icon-lg"}
              />
            </Card.Body>
          </Card>

          {
            <div className={styles.overviewMain}>
              <div>
                <label>{`Bugs (${data?.bugs})`}</label>
                {data?.bugs > 0 && (
                  <PieChart pieChartData={data?.BugsData || {}} title={``} />
                )}
              </div>
              <div>
                <label>{`TestCase (${data?.testCases})`}</label>
                {data?.testCases > 0 && (
                  <DonutChart
                    gradientDonutChartData={data?.testCasesData || {}}
                    title={``}
                  />
                )}
              </div>
              <div>
                <label>{`TestRun (${data?.testRuns})`}</label>
                {data?.testRuns > 0 && (
                  <RadialBarMultipleChart
                    multiRadarChartData={data?.testRunsData}
                    title={``}
                    totalRecords={data?.testRuns || {}}
                  />
                )}
              </div>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default Overview;
