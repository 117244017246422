import React from "react";

const Permissions = ({ roles, children }: any) => {
  const user = localStorage.getItem("user") || "";

  const userRole = user && JSON.parse(user)?.role;

  if (roles.includes(userRole)) {
    return children;
  }

  return <></>;
};

export default Permissions;
