import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import AuthLayout from '../../auth/AuthLayout';
import { Link, useLocation, Navigate } from 'react-router-dom';
import { FormInput, VerticalForm } from '../../../components';
import FeatherIcons from '../../icons/Feather';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import { Alert, Button, Col, Row } from 'react-bootstrap';

import logoLight from '../../../assets/images/cross-check-logo.png';
import useToaster from '../toast';
import config from '../../../config';

const Login = () => {
  interface UserData {
    email: string;
    password: string;
  }
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { onSuccess, onError, toastComponent } = useToaster();
  const [error, setError] = useState<any>({});

  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup.string().email().required(t('Please enter Email')),
      password: yup.string().required(t('Please enter Password')),
    })
  );

  const onSubmit = async (formData: UserData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin-portal/login`,
        formData
      );

      const { data } = res;

      const token = data?.data?.token;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(data?.data));
      navigate('/dashboard');
    } catch (error) {
      (error as any).response?.data?.msg &&
        onError((error as any)?.response?.data?.msg);
      setError((error as any)?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, [localStorage.getItem('token')]);

  return (
    <div>
      {toastComponent}

      <AuthLayout>
        <div className="auth-logo mx-auto">
          <Link to="/" className="logo logo-dark text-center">
            <span className="logo-lg">
              <img src={logoLight} alt="" height="90" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light text-center">
            <span className="logo-lg">
              <img src={logoLight} alt="" height="90" />
            </span>
          </Link>
        </div>

        <h6 className="h5 mb-0 mt-3">{t('Welcome back!')}</h6>
        <p className="text-muted mt-1 mb-4">
          {t('Enter your email address and password to access admin panel.')}
        </p>

        {error?.email && (
          <Alert variant="danger" className="my-2">
            {error?.email || error?.password}
          </Alert>
        )}

        <VerticalForm<UserData>
          onSubmit={onSubmit}
          resolver={schemaResolver}
          formClass="authentication-form"
        >
          <FormInput
            type="email"
            name="email"
            label={t('Email Address')}
            // startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
            placeholder={t('hello@coderthemes.com')}
            containerClass={'mb-3'}
          />
          <FormInput
            type="password"
            name="password"
            label={t('Password')}
            // startIcon={<FeatherIcons icon={"lock"} className="icon-dual" />}
            placeholder={t('Enter your Password')}
            containerClass={'mb-3'}
          ></FormInput>

          <div className="mb-3 text-center d-grid">
            <Button type="submit">{t('Log In')}</Button>
          </div>
        </VerticalForm>
      </AuthLayout>
    </div>
  );
};

export default Login;
