import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/asad/login";
import Dashboard from "../pages/asad/dashboard";
import Root from "./root";

import HorizontalLayout from "../layouts/Horizontal";
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import TwoColumnLayout from "../layouts/TwoColumn/";
import Users from "../pages/asad/users";
import UserManagement from "../pages/asad/user-management/user-management";
import Logout from "../pages/asad/logout/Logout";
import PrivateRoute from "./private-route";
import Workspaces from "../pages/asad/workspaces";

interface RoutesProps {}

const AllRoutes = (props: RoutesProps) => {
  const PERMISSIONS = ["Admin", "Super Admin"];

  return (
    <React.Fragment>
      <Routes>
        <Route>
          <Route path={"/"} element={<Root />} />
          <Route path={"*"} element={<Root />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/logout"} element={<Logout />} />
          <Route
            path={"/dashboard"}
            element={
              <PrivateRoute
                Component={Dashboard}
                Permissions={[
                  "Admin",
                  "Super Admin",
                  "Super Admin",
                  "Sales",
                  "Customer Support",
                ]}
              />
            }
          />
        </Route>
        <Route
          path={"/user-management"}
          element={
            <PrivateRoute
              Component={UserManagement}
              Permissions={PERMISSIONS}
            />
          }
        />
        <Route
          path={"/users"}
          element={<PrivateRoute Component={Users} Permissions={PERMISSIONS} />}
        />
        <Route
          path={"/workspaces"}
          element={
            <PrivateRoute Component={Workspaces} Permissions={PERMISSIONS} />
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
