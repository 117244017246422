import React from "react";
import Root from "./root";
import { Navigate } from "react-router-dom";
import VerticalLayout from "../layouts/Vertical";

const PrivateRoute = ({ Component, Permissions }: any) => {
  let user;
  const localUser = localStorage?.getItem("user");
  if (localUser) {
    user = JSON.parse(localUser);
  }
  const token = localStorage?.getItem("token");

  if (!user?.role || !token) {
    return <Navigate to={"/logout"} />;
  }

  if (user && !Permissions?.includes(user?.role)) {
    return <Root />;
  }
  return (
    <VerticalLayout>
      <Component />
    </VerticalLayout>
  );
};

export default PrivateRoute;
