import React, { useState } from "react";
import { Form } from "react-bootstrap";

const ControlledSwitch = ({ name, watch, setValue }: any) => {
  const [isChecked, setIsChecked] = useState(watch(name));
  const handleChange = (e: any) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    setValue(name, checked);
  };

  return (
    <Form.Check
      type="switch"
      id="custom-switch"
      className="mb-2"
      label="Active User"
      checked={isChecked}
      onChange={handleChange}
    />
  );
};

export default ControlledSwitch;
